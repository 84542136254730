import * as React from "react";

const Linkedin: React.FC = () => (
  <svg height="18" width="18" xmlns="http://www.w3.org/2000/svg">
    <path d="M-1 -1H19V19H-1z" fill="none" />
    <path d="M30.071 27.101v-.077a2.031 2.031 0 01-.05.077h.05z" />
    <path
      d="M16.65-.016H1.353C.62-.016.027.564.027 1.278v15.476c0 .714.594 1.294 1.326 1.294H16.65c.733 0 1.327-.58 1.327-1.294V1.278c0-.715-.594-1.294-1.327-1.294zM5.468 15.106H2.757V6.949h2.71v8.157zM4.112 5.835h-.018c-.91 0-1.498-.627-1.498-1.41 0-.8.607-1.409 1.534-1.409.928 0 1.498.61 1.516 1.41 0 .782-.589 1.409-1.534 1.409zm11.132 9.27h-2.71v-4.364c0-1.096-.393-1.844-1.374-1.844-.749 0-1.195.504-1.391.992-.072.174-.09.417-.09.661v4.555H6.97s.035-7.392 0-8.156h2.71v1.154c.36-.555 1.005-1.347 2.444-1.347 1.784 0 3.122 1.166 3.122 3.672v4.678h0z"
      fill="#fff"
      stroke="null"
    />
  </svg>
);

export default Linkedin;
