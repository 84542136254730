import * as React from "react";

const Github: React.FC = () => (
  <svg height="18" width="18" xmlns="http://www.w3.org/2000/svg">
    <path d="M-1 -1H19V19H-1z" fill="none" />
    <path
      d="M8.96 0a8.961 8.961 0 00-2.832 17.463c.448.082.612-.195.612-.431 0-.214-.009-.92-.013-1.668-2.492.541-3.018-1.057-3.018-1.057-.408-1.035-.995-1.31-.995-1.31-.815-.556.062-.545.062-.545.9.062 1.373.923 1.373.923.8 1.37 2.098.974 2.607.744.082-.578.314-.974.57-1.197-1.99-.226-4.082-.995-4.082-4.429 0-.979.35-1.777.921-2.404-.091-.228-.4-1.14.089-2.373 0 0 .751-.24 2.464.92a8.53 8.53 0 012.243-.302 8.548 8.548 0 012.243.302c1.71-1.16 2.462-.919 2.462-.919.49 1.235.182 2.145.09 2.372.574.627.92 1.426.92 2.404 0 3.443-2.095 4.2-4.091 4.423.323.278.608.822.608 1.658 0 1.198-.012 2.164-.012 2.459 0 .238.163.517.617.43A8.961 8.961 0 008.96 0z"
      fill="#fff"
      stroke="null"
    />
  </svg>
);

export default Github;
